import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import './App.css';
import Homepage from "./Pages/Homepage";
import Portfolio from "./Pages/Portfolio"; // Import your Portfolio component
import Loader from "./Components/Animation/Loader";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import ContactPage from "./Pages/ContactPage";

function App() {
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once
    });
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after a timeout
    }, 1500); // Adjust the duration as needed (1500ms = 1.5 seconds)
    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);

  return (
    <Router>
      <>
        {loading ? (
          <>
          <Loader />
          </>
        ) : (
          <>
            <AnimatedCursor
              innerSize={8}
              outerSize={8}
              color="0, 0, 255"
              outerAlpha={0.2}
              innerScale={0.7}
              outerScale={5}
            />
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </>
        )}
      </>
    </Router>
  );
}

export default App;
