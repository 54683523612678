// Pages/Homepage.js
import React, { Suspense } from "react";
import Navbar from "../Components/Navbar/Navbar";

// Lazy load components
const HeroSection = React.lazy(() => import("../Components/HeroSection/HeroSection"));
const Services = React.lazy(() => import("../Components/HeroSection/Services"));
const AboutUs = React.lazy(() => import("../Components/HeroSection/AboutUs"));
const Background = React.lazy(() => import("../Components/Animation/Background"));
const HowWeDoIt = React.lazy(() => import("../Components/HeroSection/HowWeDoIt"));
const Achievements = React.lazy(() => import("../Components/HeroSection/Achievements"));
const WhyNexaCode = React.lazy(() => import("../Components/HeroSection/WhyNexaCode"));
const Collab = React.lazy(() => import("../Components/Collabration/Collab"));
const Testimonials = React.lazy(() => import("../Components/HeroSection/Testimonials"));
const IconCloud = React.lazy(() => import("../Components/Animation/IconCloud"));
// const CallToAction = React.lazy(() => import("../Components/HeroSection/CallToAction"));
const Footer = React.lazy(() => import("../Components/Footers/Footer"));

export default function HomePage() {
    return (
        <div className="bg-white">
            <Navbar />
            <Suspense fallback={<div className="text-center py-10">Loading...</div>}>
                <HeroSection />
                <Background />
                <AboutUs />
                <Background />
                <Collab />
                <Background />
                <Services />
                <Background />
                <HowWeDoIt />
                <Background />
                <IconCloud />
                <Background />
                <Achievements />
                <Background />
                <WhyNexaCode />
                <Background />
                <Testimonials />
                <Background />
                <Footer />
            </Suspense>
        </div>
    );
}
