// Components/WhyNexaCode.js
import React from 'react';
import { FaRegLightbulb, FaHeadset, FaShieldAlt, FaHandsHelping, FaRocket, FaThumbsUp } from 'react-icons/fa';

const WhyNexaCode = () => {
    const reasons = [
        {
            title: "Innovative Solutions",
            description: "We leverage the latest technologies and creative strategies to provide cutting-edge solutions.",
            icon: <FaRegLightbulb className="text-4xl mb-2 text-yellow-500" />,
        },
        {
            title: "24/7 Support",
            description: "Our dedicated support team is always available to assist you with any issues or queries.",
            icon: <FaHeadset className="text-4xl mb-2 text-blue-500" />,
        },
        {
            title: "Security First",
            description: "We prioritize your security, ensuring that your data and applications are safe and protected.",
            icon: <FaShieldAlt className="text-4xl mb-2 text-green-500" />,
        },
        {
            title: "Client-Centric Approach",
            description: "We focus on building strong relationships with our clients, understanding their unique needs.",
            icon: <FaHandsHelping className="text-4xl mb-2 text-purple-500" />,
        },
        {
            title: "Rapid Deployment",
            description: "Our agile methodologies ensure that we deliver solutions quickly without compromising quality.",
            icon: <FaRocket className="text-4xl mb-2 text-red-500" />,
        },
        {
            title: "Proven Results",
            description: "We have a track record of delivering successful projects that drive business growth and client satisfaction.",
            icon: <FaThumbsUp className="text-4xl mb-2 text-orange-500" />,
        },
    ];

    return (
        <section className="bg-gray-100 py-12">
            <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
                <h2 
                    className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-blue-500 to-pink-500"
                    data-aos="fade-up">
                    Why Choose NexaCode Solutions?
                </h2>
                <p className="mb-8 text-lg text-gray-700" data-aos="fade-up" data-aos-delay="100">
                    Discover the key reasons why we are the preferred choice for businesses looking for digital solutions:
                </p>
                <div className="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {reasons.map((reason, index) => (
                        <div 
                            key={index} 
                            className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl"
                            data-aos="fade-up" 
                            data-aos-delay={(index + 1) * 100} // Added delay for each reason
                        >
                            {reason.icon} {/* Render the icon */}
                            <h3 className="text-xl font-semibold mb-2" data-aos="fade-up" data-aos-delay={(index + 1) * 100 + 200}>
                                {reason.title}
                            </h3>
                            <p className="text-gray-600" data-aos="fade-up" data-aos-delay={(index + 1) * 100 + 300}>
                                {reason.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyNexaCode;
