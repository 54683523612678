import React, { Suspense } from "react";
import Navbar from "../Components/Navbar/Navbar";

const Portfolio = () => {
    const Footer = React.lazy(() => import("../Components/Footers/Footer"));
    const PortfolioSection = React.lazy(() => import("../Components/Portfolio/PortfolioSection"));
    const HeroSection = React.lazy(() => import("../Components/HeroSection/HeroSection"));
    const Background = React.lazy(() => import("../Components/Animation/Background"));
    const IconCloud = React.lazy(() => import("../Components/Animation/IconCloud"));
    const HowWeDoIt = React.lazy(() => import("../Components/HeroSection/HowWeDoIt"));

    return (
        <>
            <Navbar />
            <Suspense fallback={<div className="text-center py-10">Loading...</div>}>
                <HeroSection />
                <Background />
                <IconCloud />
                <Background />
                <PortfolioSection />
                <Background />
                <HowWeDoIt />
                <Background />
                <Footer />
            </Suspense>
        </>
    );
};

export default Portfolio;
