// Pages/ContactPage.js
import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footers/Footer";
import Background from "../Components/Animation/Background";
import WhyNexaCode from "../Components/HeroSection/WhyNexaCode";

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to an API)
        console.log(formData);
        // Reset form after submission
        setFormData({ name: "", email: "", message: "" });
    };

    return (
        <div className="bg-white">
            <Navbar />
            <Background />
            <div className="container mx-auto p-6">
                <h1 className="text-3xl text-center font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-blue-500 to-pink-500">Contact Us</h1>
                <div className="flex flex-col md:flex-row md:justify-between">
                    <div className="md:w-1/2 mb-6">
                        <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
                        <p>If you have any questions or inquiries, feel free to reach out to us!</p>
                        <ul className="mt-4">
                            <li className="mb-2">
                                <strong>Email:</strong> info@nexacodesolution.com
                            </li>
                            <li className="mb-2">
                                <strong>Phone:</strong> +971 52 825 1053
                            </li>
                            <li>
                                <strong>Location:</strong> UAE, Dubai
                            </li>
                        </ul>
                    </div>
                    <div className="md:w-1/2">
                        <h2 className="text-2xl font-semibold mb-4">Submit form to stay in touch</h2>
                        <form onSubmit={handleSubmit} className="bg-gray-100 p-4 rounded-lg">
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <button
                                type="submit"
                                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Background />
            <WhyNexaCode />
            <Footer />
        </div>
    );
};

export default ContactPage;
