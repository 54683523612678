import React, { useEffect } from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaInstagram, FaLinkedinIn, FaSkype, FaWhatsapp, FaPinterest } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Footer.css";

function Footer() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {/* Nexacode App Section */}
                    <div className="flex flex-col items-center mb-4" data-aos="fade-up">
                        <a href="/#">
                            <img src="Images/logo.png" alt="Nexacode Logo" className="h-32 w-32 mb-3" />
                        </a>
                        <p className="text-center text-gray-400">
                            #1 Digital Solution With <b>5+</b> Years Of Experience.
                        </p>
                    </div>

                    {/* Contact Information */}
                    <div className="flex flex-col items-center" data-aos="fade-up">
                        <h6 className="text-lg font-semibold text-white mb-3">Contact Information</h6>
                        <li className="mb-2 flex justify-center items-center">
                            <FaPhone size={30} className="text-primary mr-2" />
                            <span>+971 52 825 1053</span>
                        </li>
                        <li className="mb-2 flex justify-center items-center">
                            <FaEnvelope size={30} className="text-primary mr-2" />
                            <span>info@nexacodesolution.com</span>
                        </li>
                        <li className="flex justify-center items-center">
                            <FaMapMarkerAlt size={30} className="text-primary mr-2" />
                            <span>UAE, Dubai</span>
                        </li>
                    </div>

                    {/* Social Media Links */}
                    <div className="flex flex-col items-center" data-aos="fade-up">
                        <h6 className="text-lg font-semibold text-white mb-3">Follow Us</h6>
                        <div className="flex justify-center gap-4">
                            <a href="https://www.facebook.com/profile.php?id=61566632271776" className="text-white hover:text-blue-600 transition duration-200">
                                <FaFacebookF size={30} />
                            </a>
                            <a href="https://www.instagram.com/nexa.code.solution?igsh=b2EwYnljZDAxdmJs" className="text-white hover:text-pink-500 transition duration-200">
                                <FaInstagram size={30} />
                            </a>
                            <a href="https://www.linkedin.com/company/nexa-code-solution/" className="text-white hover:text-blue-500 transition duration-200">
                                <FaLinkedinIn size={30} />
                            </a>
                            <a href="https://skype.com" className="text-white hover:text-blue-500 transition duration-200">
                                <FaSkype size={30} />
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=971528251053" className="text-white hover:text-green-500 transition duration-200">
                                <FaWhatsapp size={30} />
                            </a>
                            <a href="https://www.pinterest.com/Nexacodesolution/" className="text-white hover:text-red-500 transition duration-200">
                                <FaPinterest size={30} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-8 text-gray-400 text-sm">
                    <p>&copy; {new Date().getFullYear()} Nexacode Solution. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
