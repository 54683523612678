'use client';

import React, { useState } from "react";
import { Dialog, DialogPanel } from '@headlessui/react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons
import {  Link } from "react-router-dom";

function Navbar() {
    const navigation = [
        { name: 'Home', href: '/' },
        { name: 'Our Portfolio', href: '/portfolio' }, // Update this line
        { name: 'Contact us', href: '/contact' },
    ];
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                            <img
                                src="./Images/logo.png" // Replace with the actual path to your animated logo
                                alt="Nexacode Solution Logo"
                                className="h-10 w-auto transition-transform duration-300 hover:scale-110"
                            />
                        </span>
                    </a>
                </div>

                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <FaBars aria-hidden="true" className="h-6 w-6" /> {/* Using FaBars icon */}
                    </button>
                </div>
                
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <Link 
                            key={item.name} 
                            to={item.href} 
                            smooth={true}
                            duration={500}
                            className="hover:text-2xl transition-all duration-300 text-sm font-semibold leading-6 text-gray-900"
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            </nav>

            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Nexacode Solution</span>
                            <img
                                alt="Nexacode Logo"
                                src="./Images/logo.png"
                                className="h-8 w-auto"
                            />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <FaTimes aria-hidden="true" className="h-6 w-6" /> {/* Using FaTimes icon */}
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        smooth={true}
                                        duration={500}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        onClick={() => setMobileMenuOpen(false)} // Close the menu on link click
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Log in
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}

export default Navbar;
